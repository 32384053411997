import { useState, useEffect } from "react";
import "../css/application.css";
import { Box, Divider } from "@mui/material";
import Mapgl from "../components/mapboxApp/mapgl";
import SplitButton from "../components/tools/splitButton";
import JsonTable from "../components/plots/jsonTable";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PoTree from "../components/plots/potree";

import { HSPA_API_BASE, GET_COLLECTION_FILES, GET_COLLECTION_DATA, HSPA_TITLE} from "../config/constants";

/*
Collection Page

* Handles state of current collection file
* Handles state of map center coordinate
* Handles state of all collection files
*/
function Collection({ dimensions }) {
  const [allFiles, setFiles] = useState(null);
  const [currColl, setColl] = useState(null);
  const [lat, setLat] = useState(34.1017);
  const [lng, setLng] = useState(-118.4438);
  const [collData, setData] = useState([]);


  // Populates all collection files from API when page loads
  useEffect(() => {
    fetch(HSPA_API_BASE + GET_COLLECTION_FILES)
      .then((response) => response.json())
      .then(
        (result) => {

          const propertyNames = Object.keys(result);

  // Iterate through the property names and log their values
        propertyNames.forEach((propertyName) => {
          const propertyValue = result[propertyName];
          console.log(`${propertyName}:`, propertyValue);
        });
          setFiles(result);
          setColl(result[Object.keys(result)[0]][0]);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        console.error("There was a problem fetching geoJson Data", error);
      });
  }, []);

  // Updates collection data based on selected collection file - called when dropdown is modified
  useEffect(() => {
    fetch(HSPA_API_BASE + GET_COLLECTION_DATA + `?file=` + currColl)
      .then((response) => response.json())
      .then(
        (result) => {
          console.log(result)
          const promises = result.features.map(feature => 
            fetchStreetName(
              feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)][1],
              feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)][0]
            ).then((streetName) => {
              const nameParts = streetName.split(' ');
              if (!isNaN(nameParts[0])) {
                nameParts.shift();
              }
              feature.properties.ST_NAME = nameParts.join(' ').split(',')[0];
            })
          );

          Promise.all(promises).then(() => {
            setData(result);
          });
          setData(result);
          setLng(result.features[0].geometry.coordinates[0][0]);
          setLat(result.features[0].geometry.coordinates[0][1]);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [currColl]);

  async function fetchStreetName(lat, lng) {
    const accessToken = 'pk.eyJ1IjoiYXJqdW5uYWlyMTUiLCJhIjoiY2xnY3YwcWdpMDJjMTNkcm5xa3FjdWIzNCJ9.hVMks5_vDvzMgseTHYTNKg';
    const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`);
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      return data.features[0].place_name || 'Unknown Street';
    } else {
      console.error('Error fetching street name:', data);
      return 'Unknown Street';
    }
  }
  

  useEffect(() => {
    console.log(collData)
    // if (collData && Array.isArray(collData.features)) {
    //   const tempData = { ...collData, features: [...collData.features] }; // Create a shallow copy of collData
    //   tempData.features.forEach((feature) => {
    //     if (feature.geometry && feature.geometry.coordinates) {
    //       fetchStreetName(
    //         feature.geometry.coordinates[0][1],
    //         feature.geometry.coordinates[0][0]
    //       ).then((streetName) => {
    //         feature.properties.ST_NAME = streetName.split(',')[0];
    //       });
    //     }
    //   });
    //   setData(tempData);
    // }

    // console.log(collData)
  }, [collData])

  


  useEffect(() => {
    console.log(collData)
  }, [collData])

  return (
    <div className="application">
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="intro">
            <h1>{HSPA_TITLE} Collection</h1>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <h2>Select Collection:</h2>
                <FormControl sx={{ m: 1, minWidth: 120, width: "90%" }}>
                  <Select
                    native
                    value={currColl}
                    onChange={(e) => setColl(e.target.value)}
                  >
                    {allFiles == null ? (
                      <div />
                    ) : (
                      Object.keys(allFiles).map((files) => (
                        <optgroup label={files} key={files}>
                          {allFiles[files].map((file) => (
                            <option value={file} key={file}>
                              {file}
                            </option>
                          ))}
                        </optgroup>
                      ))
                    )}
                  </Select>
                </FormControl>
                <div className="divBar">
                  <Divider variant="middle" />
                </div>
                <div className="evacDataContainer">
                  {collData == null || collData.features == null ? (
                    <h2>Data Loading...</h2>
                  ) : (
                    <div>
                      <h2>Collection From {currColl.substring(0, 10)}</h2>
                      <ul>
                        <li className="evacData">
                          Sections: {collData.features.length}
                        </li>
                        <li className="evacData">
                          Miles:{" "}
                          {Number.parseFloat(
                            collData.features
                              .map((feature) =>
                                parseInt(feature.properties.length)
                              )
                              .reduce((a, b) => a + b, 0) / 5280
                          ).toFixed(4)}
                        </li>
                        <li className="evacData">
                          Collection Name: {currColl.substring(0, 19)}
                        </li>
                        <li className="evacData">
                          Paved Surface:{" "}
                          {
                            collData.features.filter(
                              (feature) => feature.properties.Road_Surface
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Curb Presence:{" "}
                          {
                            collData.features.filter(
                              (feature) => feature.properties.Curb_Presence > 0
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Sidewalk Presence:{" "}
                          {
                            collData.features.filter(
                              (feature) =>
                                feature.properties.Sidewalk_Presence > 0
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Uphill Slope:{" "}
                          {
                            collData.features.filter(
                              (feature) =>
                                feature.properties.Slope_Presence === 1 ||
                                feature.properties.Slope_Presence === 3
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Downhill Slope:{" "}
                          {
                            collData.features.filter(
                              (feature) =>
                                feature.properties.Slope_Presence === 3 ||
                                feature.properties.Slope_Presence === 2
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Parking Cars:{" "}
                          {
                            collData.features.filter(
                              (feature) => feature.properties.Parking_Cars
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Fire Hydrants:{" "}
                          {
                            collData.features.filter(
                              (feature) => feature.properties.Fire_Hydrants
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Street Trees:{" "}
                          {
                            collData.features.filter(
                              (feature) => feature.properties.Street_Trees
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Trash Bins:{" "}
                          {
                            collData.features.filter(
                              (feature) => feature.properties.Trash
                            ).length
                          }{" "}
                          / {collData.features.length}
                        </li>
                        <li className="evacData">
                          Mean Road Width:{" "}
                          {Number.parseFloat(
                            (collData.features
                              .map((feature) => feature.properties.Road_Width)
                              .reduce((a, b) => a + b, 0) *
                              3.28084) /
                              collData.features.length
                          ).toFixed(2)}{" "}
                          ft
                        </li>
                      </ul>
                      <div className="evacData">
                        <SplitButton
                          downloadData={collData}
                          fileName="Download"
                        />
                      </div>
                      <div className="evacData"></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col centerVertical">
                <Mapgl
                  geoData={[
                    {
                      data: collData.features,
                      color: "red",
                      id: "street",
                      width: 4,
                    },
                  ]}
                  width={(dimensions.width * 70) / 100}
                  height={650}
                  mapZoom={11}
                  initLat={34.1017}
                  initLng={-118.4438}
                  polyControl={false}
                  controls={["full"]}
                  lat={lat}
                  lng={lng}
                  callback={() => {}}
                />
              </div>
            </div>
            <div className="row">
              {collData == null ? (
                <div />
              ) : (
                <div className="downloadButton">
                  <JsonTable
                    geoData={collData.features}
                    headers="collection"
                    width="90vw"
                  ></JsonTable>
                </div>
              )}
            </div>
            <div className="divider">
              <Divider variant="middle" />
            </div>
            <div className="row">
              <div className="poTree">
                <PoTree
                  currLink={currColl ? currColl.substring(0, 19) : ""}
                  width={(dimensions.width * 90) / 100}
                  height={700}
                />
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Collection;
